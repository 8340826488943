import React, {
  createContext,
  useContext,
  useReducer,
  useRef,
  useEffect,
} from "react";
import PropTypes from "prop-types";

import { SET_LOGIN, SET_USER } from "./actions/auth";
import { SET_PRODUCTION_LIST } from "./actions/production";
import { SET_SNACKBAR } from "./actions/snackbar";
import { SET_BUDGET_LIST } from "./actions/budget";
import { adminContextStore } from "./store/createStore";

const AdminStateContext = createContext();
const AdminDispatchContext = createContext();

const initialState = {
  login: {
    submitting: false,
    isLogin: false,
  },
  productionList: { loading: true, pagination: {} },
  budget: {
    loading: true,
  },
  snackbar: {
    open: false,
    message: "",
    severity: "success",
  },
  test: {
    isTesting: false,
  },
};

const reducer = (state, action) => {
  const setState = (obj) => Object.assign({}, state, obj);

  switch (action.type) {
    case SET_LOGIN:
      return setState({
        login: Object.assign({}, state.login, action.payload),
      });

    case SET_USER:
      return setState({
        userData: Object.assign({}, state.userData, action.payload),
      });

    case SET_PRODUCTION_LIST:
      return setState({
        productionList: Object.assign({}, state.productionList, action.payload),
      });

    case SET_SNACKBAR:
      return setState({
        snackbar: Object.assign({}, state.snackbar, action.payload),
      });

    case SET_BUDGET_LIST:
      return setState({
        budget: Object.assign({}, state.budget, action.payload),
      });

    default:
      return state;
  }
};

export const useAdminState = () => {
  const context = useContext(AdminStateContext);
  if (!context) throw new Error("useAdminState must be used within a Provider");

  return context;
};

export const useAdminDispatch = () => {
  const context = useContext(AdminDispatchContext);
  if (!context)
    throw new Error("useAdminDispatch must be used within a Provider");

  return context;
};

function AdminContext(props) {
  const { children } = props;

  const [state, dispatch] = useReducer(reducer, initialState);
  const stateRef = useRef(initialState);

  useEffect(() => {
    stateRef.current = state;
    adminContextStore._onStateUpdated();

    return () => {
      adminContextStore.unmount();
    };
  }, [state]);

  if (!adminContextStore.isReady) {
    adminContextStore.init({
      dispatch: (params) => dispatch(params),
      getState: () => ({ ...stateRef.current }),
    });
  }

  return (
    <AdminStateContext.Provider value={state}>
      <AdminDispatchContext.Provider value={dispatch}>
        {children}
      </AdminDispatchContext.Provider>
    </AdminStateContext.Provider>
  );
}

AdminContext.propTypes = {
  children: PropTypes.any.isRequired,
};

export default AdminContext;
