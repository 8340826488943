import React from "react"
import { createTheme } from "@material-ui/core/styles"
import { ThemeProvider } from "@material-ui/styles"
import AdminContextProvider from "./src/context/AdminContext"
import Layout from "./src/components/layout"
const theme = createTheme({
  palette: {
    primary: {
      main: "#0071cd",
    },
    secondary: {
      main: "#CDCDCD",
    },
    tertiary: {
      main: "#f44336",
    },
  },
})

export const wrapRootElement = ({ element, props }) => (
  <AdminContextProvider>
    <ThemeProvider theme={theme}>
      <Layout {...props}>{element}</Layout>
    </ThemeProvider>
  </AdminContextProvider>
)
