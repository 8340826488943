import { UserManager } from "oidc-client-ts";
const oidcConfig = {
  authority: process.env.GATSBY_AUTHORITY_URL,
  client_id: process.env.GATSBY_CLIENT_ID,
  redirect_uri: process.env.GATSBY_REDIRECT_URL,
  response_type: "code",
  scope: "openid profile email groups params",
};

const userManager = new UserManager(oidcConfig);

export function login() {
  userManager.signinRedirect();
}

export function handleCallback() {
  return userManager.signinRedirectCallback();
}

export function logout() {
  userManager.signoutRedirect();
}

export function getUser() {
  return userManager.getUser();
}
