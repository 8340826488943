import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import Header from "./header";
import "./layout.css";
import { Wrapper } from "./styles";
import Snackbar from "../shared/Snackbar";
import { navigate } from "gatsby";
import { adminContextStore } from "../context/store/createStore";
import { SET_LOGIN } from "../context/actions/auth";

const Layout = ({ children }) => {
  const { dispatch } = adminContextStore;
  const isBrowser = typeof window !== "undefined";

  const getToken = useMemo(() => {
    if (!isBrowser) return null;
    const token = sessionStorage.getItem(
      `oidc.user:${process.env.GATSBY_AUTHORITY_URL}:${process.env.GATSBY_CLIENT_ID}`
    );
    return token && token !== "undefined" ? token : null;
  }, [isBrowser]);

  const pathname = isBrowser ? window.location.pathname : "/";

  useEffect(() => {
    if (!getToken) {
      navigate("/login");
    } else if (getToken) {
      dispatch({
        type: SET_LOGIN,
        payload: {
          submitting: false,
          isLogin: true,
          user: JSON.parse(getToken),
        },
      });
      navigate("/");
    }
  }, [getToken]);

  return (
    <Wrapper>
      <Snackbar />
      {!pathname.includes("login") && <Header />}

      <div>
        <main>{children}</main>
        {!pathname.includes("login") && (
          <footer>© {new Date().getFullYear()}, Lotuss PMO</footer>
        )}
      </div>
    </Wrapper>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
